import React from "react"

import Layout from "../layout"

const NotFoundPage = () => (
  <Layout>
    <h1
      style={{
        textAlign: "center",
        fontFamily: "Droid Serif, georgia, serif",
        fontWeight: 500,
      }}
    >
      404...
    </h1>
    <h1
      style={{
        textAlign: "center",
        fontFamily: "Droid Serif, georgia, serif",
        fontWeight: 500,
      }}
    >
      Sorry, but the page you are trying to view does not exist.
    </h1>
    <div style={{ marginTop: "50px", fontSize: "1.5em", textAlign: "center" }}>
      <p>
        <a href="/learning-resources/">Learning Resources</a>
      </p>
      <p>
        <a href="/visit/information/opening-times-entry-charges">
          Opening Hours and Entry Charges
        </a>
      </p>
      <p>
        <a href="/whats-on/news">Latest News</a>
      </p>
      <p>
        <a href="/whats-on/events">Upcoming Events</a>
      </p>
      <p>
        <a href="/get-involved/contact">Contact Information</a>
      </p>
    </div>
  </Layout>
)

export default NotFoundPage
